@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

.label-style {
  /* General styling */
  color: #fff; /* White text color */
  font-weight: bold; /* Bold text */
  border-radius: 5px; /* Rounded corners */
  padding: 5px 10px; /* Padding for spacing */
  margin: 5px; /* Add margin around labels */
  opacity: 0.9; /* Slightly transparent */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-hover:hover {
  color: red !important;
}


/* Color classes with adjusted background colors for better contrast with white text */
.label-blue {
  background-color: #007bff; /* A deeper blue */
}

.label-green {
  background-color: #28a745; /* A deeper green */
}

.label-yellow {
  background-color: #ffc107; /* A deeper yellow */
}

.label-pink {
  background-color: #ff85b3; /* A deeper pink */
}

.pre-wrap {
  white-space: pre-wrap; /* Allows text to wrap */
  word-break: break-word; /* Ensures long words do not overflow */
}

.tableScroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.ui.table .freeze {
  position: sticky;
  right: 0;
  background-color: white; /* Match your table's background */
  z-index: 2; /* Ensure it sits above horizontally scrolled content */
}

.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8em;
  margin-left: 1px;
}

.mobile-menu {
  display: flex;
  justify-content: space-around; /* Spread menu items evenly in mobile */
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .mobile-menu .item {
      flex: 1; /* Ensure each menu item takes up equal space */
      text-align: center; /* Center align text or icons */
  }

  /* Reduce the size of icons to make them fit well in mobile view */
  .mobile-menu .item > i.icon {
      font-size: 1.5em;
  }

  /* Optional: Style notification count */
  .notification-count {
      background-color: red;
      color: white;
      border-radius: 50%;
      padding: 2px 5px;
      font-size: 0.8em;
      margin-left: 5px;
  }
}

/* Flexbox styling for mobile navbar */
.mobile-navbar {
  display: flex;
  justify-content: space-around; /* Spread menu items evenly in mobile */
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .mobile-navbar .item {
      flex: 1; /* Ensure each menu item takes up equal space */
      text-align: center; /* Center align text or icons */
  }

  /* Reduce the size of icons to make them fit well in mobile view */
  .mobile-navbar .item > i.icon {
      font-size: 1.5em;
  }
}

/* AIResumeUploader css */

/* Default: horizontally aligned */
.upload-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.upload-container input {
  margin-right: 0.5em;
}

/* For mobile view, stack vertically */
@media (max-width: 768px) {
  .upload-container {
    flex-direction: column;
    align-items: stretch;
  }

  .upload-container input {
    margin-right: 0; /* Reset margin */
    margin-bottom: 1em; /* Add margin between input and button group */
  }

  .upload-container .ui.button.group {
    width: 100%;
  }
}




